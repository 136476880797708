import React from "react";
import AutocarreraIcon from "../assets/images/autocarrera_logo.png";
import BazePortIcon from "../assets/images/bazeport_logo.png";
import OneAccountingIcon from "../assets/images/one_accounting_logo.png";
import ThureTrykkIcon from "../assets/images/thure_trykk_logo.png";
import BabolatIcon from "../assets/images/babolat_orange_logo.png";

export default function Partners() {
  return (
    <div>
      <section className="lead-section">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h2 style={{ fontSize: `30px` }}>Samarbeidspartnere</h2>
        </div>

        <div className="row d-flex justify-content-center">
          {/*Bazeport*/}
          <div className="col-8">
            <div className="fill">
              <img
                src={BazePortIcon}
                alt="Bazeport"
                style={{ maxHeight: 200 }}
              />
            </div>
          </div>
          {/*One Accounting*/}
          <div className="col-8">
            <div className="fill">
              <img
                src={OneAccountingIcon}
                alt="OneAccounting"
                style={{ maxHeight: 200 }}
              />
            </div>
          </div>
          {/*Autocarrera*/}
          <div className="col-8">
            <div className="fill">
              <img
                src={AutocarreraIcon}
                alt="Autocarrera"
                style={{ maxHeight: 200 }}
              />
            </div>
          </div>
          {/*Thure Trykk*/}
          <div className="col-8">
            <div className="fill">
              <img
                src={ThureTrykkIcon}
                alt="Thure Trykk"
                style={{ maxHeight: 100 }}
              />
            </div>
          </div>
          {/*Babolat*/}
          <div className="col-8">
            <div className="fill">
              <img src={BabolatIcon} alt="Babolat" style={{ maxHeight: 100 }} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
